@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  @apply bg-gradient-to-br from-background-700 to-background-800 bg-no-repeat;
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
}

button:disabled,
button[type='submit']:disabled {
  @apply opacity-50;
}

/* Style the custom checkboxes with rounded design */
.checkbox-round {
  display: none;
}

.checkbox-round + label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  min-height: 28px;
  min-width: 28px;
  max-height: 28px;
  max-width: 28px;
  display: inline-block;
  position: relative;
  margin-right: 10px;
}

.checkbox-round + label:after {
  content: '';
  position: absolute;
  top: 8px;
  left: 7px;
  width: 12px;
  height: 6px;
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
  opacity: 0;
}

.checkbox-round:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.checkbox-round:checked + label:after {
  opacity: 1;
}

.fredoka-300 {
  font-family: 'Fredoka', serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings: 'wdth' 100;
}

.fredoka-400 {
  font-family: 'Fredoka', serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: 'wdth' 100;
}

.fredoka-500 {
  font-family: 'Fredoka', serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings: 'wdth' 100;
}

.fredoka-550 {
  font-family: 'Fredoka', serif;
  font-optical-sizing: auto;
  font-weight: 560;
  font-style: normal;
  font-variation-settings: 'wdth' 100;
}

.fredoka-600 {
  font-family: 'Fredoka', serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: 'wdth' 100;
}

.fredoka-650 {
  font-family: 'Fredoka', serif;
  font-optical-sizing: auto;
  font-weight: 650;
  font-style: normal;
  font-variation-settings: 'wdth' 100;
}

.fredoka-700 {
  font-family: 'Fredoka', serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings: 'wdth' 100;
}

.poppins-thin {
  font-family: 'Poppins', serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: 'Poppins', serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: 'Poppins', serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: 'Poppins', serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: 'Poppins', serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: 'Poppins', serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: 'Poppins', serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: 'Poppins', serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: 'Poppins', serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: 'Poppins', serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: 'Poppins', serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: 'Poppins', serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: 'Poppins', serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: 'Poppins', serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: 'Poppins', serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: 'Poppins', serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: 'Poppins', serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: 'Poppins', serif;
  font-weight: 900;
  font-style: italic;
}

.inter {
  font-family: 'Inter', sans-serif;
}

.text-stroke-pink-43 {
  -webkit-text-stroke: 1px #f27ba96e;
}

.text-stroke-darkpink {
  -webkit-text-stroke: 1px #9e4b49;
}

.event-card:hover.event-card::before {
  content: 'READ MORE' !important;
}

.react-select__multi-value {
  background-color: transparent !important;
}

/* .event-banner:nth-of-type(1) {
  transform: translateX(calc(50%));
} */
/* .event-banner:nth-of-type(2) {
  transform: translateX(calc(30%));
}
.event-banner:nth-of-type(3) {
  transform: translateX(calc(15%)) scale(1.1);
} */

@media (max-width: 1023px) {
  /* .event-banner:nth-of-type(1) {
    transform: translateX(calc(20%));
  } */
  /* .event-banner:nth-of-type(2) {
    transform: translateX(calc(0%));
  }
  .event-banner:nth-of-type(3) {
    transform: translateX(calc(-17%)) scale(1.1);
  } */
  .clip-parallelogram {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}

@layer utilities {
  .clip-parallelogram {
    /* clip-path: polygon(0 0, 80% 0, 100% 100%, 20% 100%); */
    clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 100%);
  }
}

/* Removing arrows from input number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
